@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: 'Optimistic';
        src: url('https://react.dev/fonts/Optimistic_Display_W_SBd.woff2') format('woff2');
        font-weight: normal;
        font-style: normal
    }
}